import React from "react";
import "../styles/Projects.css";
import {ProjectItem} from "../components/projectItem";
import {ProjectList} from "../helpers/ProjectList";

export const Projects = () => {
    return (
        <div className="projects">
            <h1> My Personal Projects: </h1>
            {/* looping through the helper to display each project in the array */}
            <div className="projectList">
                {ProjectList.map((project, index) => {
                    return <ProjectItem id={index} name={project.name} image={project.image}/>
                })}
            </div>
            
        </div>
    );
};