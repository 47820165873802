import "../styles/Footer.css";
import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Email from "@material-ui/icons/Email";
import GitHub from "@material-ui/icons/GitHub";
import Link from "@material-ui/core/Link";

export const Footer = () => {
    return (
        <div className="footer">
            <div className="socialMedia">
                <Link href="mailto:aaron.morgan742@gmail.com"  >
                    <Email />
                </Link>
                <Link href="https://uk.linkedin.com/in/aaron-morgan742" target="_blank" rel="noopener noreferrer" >
                    <LinkedInIcon />
                </Link>

                <Link href="https://github.com/Aaron-Morgan98" target="_blank" rel="noopener noreferrer" >
                    <GitHub/>
                </Link>
                <p>
                    &copy; {new Date().getFullYear()} aaron-morgan.com
                </p>
            </div>
        </div>
    );
};