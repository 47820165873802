import React from "react";
import {useNavigate} from "react-router-dom";


//Component created to display our projects. It will pass two props, a name and an image.
export const ProjectItem = ({image, name, id})  => {
    const navigate = useNavigate();

    return (
        <div className="projectItem" onClick={() => {navigate("/project/" + id)}}>
            <div style={{ backgroundImage: `url(${image})` }} className="bgImage" />
            <h1> {name} </h1>
        </div>
    );
};

