import React from "react";
import "../styles/ProjectDisplay.css";
import {useParams} from "react-router-dom";
import {ProjectList} from "../helpers/ProjectList";
import GitHub from "@material-ui/icons/GitHub";
import Link from "@material-ui/core/Link";

export const ProjectDisplay = ()  => {
    const {id} = useParams();
    const project = ProjectList[id];

    return (
        <div className="project">
            <h1> {project.name} </h1>
            <img src={project.image} />
            <p>
                <b>Skills:</b> {project.skills}
            </p>
            <Link href={project.github} target="_blank" rel="noopener noreferrer" >
                <GitHub/>
            </Link>
        </div>
    );
};

