import socialMediaWebsite from "../assets/socialMediaWebsite.png";
import bookReviewBlog from "../assets/bookReviewBlog.jpg";
import todoList from "../assets/todoList.jpg";
import pong from "../assets/pong.png";

//helper created to keep our project list more organised
export const ProjectList = [
    {
        name: "Social Media Website",
        image: socialMediaWebsite,
        skills: "ReactJS, Firebase, Typescript",
        github: "https://github.com/Aaron-Morgan98/react-social-media-website",
    },
    {
        name: "Book Review Blog",
        image: bookReviewBlog,
        skills: "Node/ExpressJS, EJS, PostgreSQL, RESTful APIs, Bootstrap",
        github: "https://github.com/Aaron-Morgan98/the-honest-reader"
    },
    {
        name: "Pong",
        image: pong,
        skills: "Java, JFrame",
        github: "https://github.com/Aaron-Morgan98/Pong",
    },
    
];