import React from "react";
import "../styles/Home.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Email from "@material-ui/icons/Email";
import GitHub from "@material-ui/icons/GitHub";
import Link from "@material-ui/core/Link";

export const Home = () => {
    return (
        <div className="home">
            <div className="about">
                <h2> Hi, I'm Aaron Morgan</h2>
                <div className="prompt">
                    <p>
                        A Full Stack Developer.
                    </p>
                    <div className="socialMedia">
                        <Link href="mailto:aaron.morgan742@gmail.com"  >
                            <Email />
                        </Link>

                        <Link href="https://uk.linkedin.com/in/aaron-morgan742" target="_blank" rel="noopener noreferrer" >
                            <LinkedInIcon />
                        </Link>

                        <Link href="https://github.com/Aaron-Morgan98" target="_blank" rel="noopener noreferrer" >
                            <GitHub/>
                        </Link>
                    </div>
                </div>
            </div>
            <div class="custom-shape-divider-bottom-1705180295">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                </svg>
            </div>
            <div className="scroll">
                <p className="scrollLeft">Scroll For Skills! ↓</p>
                <p className="scrollRight">Scroll For Skills! &#8595;</p>
            </div>
            <div className="skills"> 
                <h1> Skills </h1>
                <ol className="list">
                    <li className="item">
                        <h2> Front-End </h2>
                        <span> 
                            React, jQuery, Redux, HTML, CSS, NPM, Bootstrap        
                        </span>
                    </li>
                    <li className="item">
                        <h2> Back-End </h2>
                        <span> 
                            Spring/SpringBoot, ASP.NET, PostgreSQL, NodeJS, ExpressJS, Git, Flask,  Firebase, REST APIs,
                        </span>
                    </li>
                    <li className="item">
                        <h2> Languages </h2>
                        <span> 
                            Java, C#, JavaScript, TypeScript, Python
                        </span>
                    </li>
                </ol>
            </div>
        </div>
    );
};