import React from "react";
import FileCopy from "@material-ui/icons/FileCopy";
import Link from "@material-ui/core/Link";
import "../styles/Experiance.css"

export const Experiance = () => {
    return (
        <>
        <div className="prompt">
            <h1>Click below to view my CV.</h1>
            <p> ⬇ </p>
        </div>
        <div className="link">
            <Link href="https://docs.google.com/document/d/1Y7s8DAMQwq4SSJCX2_3_aEtqsMbJ-y-H/edit?usp=sharing&ouid=102188609391117056991&rtpof=true&sd=true"
            target="_blank" rel="noopener noreferrer"  >
                <FileCopy />
            </Link>

            
            
        </div>
        </>
    );
};