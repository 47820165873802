import "../styles/Navbar.css";
import React, {useState, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import ReorderIcon from "@material-ui/icons/Reorder";

export const Navbar = () => {
    const [expandNavbar, setExpendNavbar] = useState(false);

    const location = useLocation();

    //this hook is used to close the navbar menu (on mobile) when a link is clicked
    useEffect(() => {
        setExpendNavbar(false);
    }, [location]);

    return (
        //When screen reaches a certain size (mobile) and our navbar button appears
        //either the "open" or "closed" css will be applied depending on if clicked
        <div className="navbar" id={expandNavbar ? "open" : "close"}>
            <div className="toggleButton">
                <button onClick={() => {
                    setExpendNavbar((prev) => !prev)
                    }}
                >
                    <ReorderIcon /> 
                </button>
            </div>
            <div className="links">
                <Link to={"/"}> Home </Link>
                <Link to={"/projects"}> Projects </Link>
                <Link to={"/experiance"}> CV </Link>
            </div>
        </div>
    );
};